import React from "react";
import PropTypes from "prop-types";

import { SessionContext } from "layouts/Dashboard.jsx";
import { NotificationManager } from "react-notifications";
import Api from "modules/api.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withI18n from "modules/withI18n";
import withStyles from "@material-ui/core/styles/withStyles";

import Form from '@rjsf/material-ui';
import validator from '@rjsf/validator-ajv8';

import "./cartwatch_config.css";

class CartwatchConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      block_rendering: true
    };
    this.reloadConfig = this.reloadConfig.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData(this.props.context.instance_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.instance_id !== prevProps.context.instance_id) {
      this.setState({block_rendering: true});
      if (typeof (this.props.context.instance_id) != "undefined") {
        this._isMounted && this.loadData(this.props.context.instance_id);
      }
    }
  }

  unblockRendering() {
    this.setState({ block_rendering: false });
  }

  reloadConfig() {
    this._isMounted && this.loadData(this.props.context.instance_id);
  }

  loadData(instance_id) {
    if (instance_id === undefined) {
      return;
    }
    Api.get(`/api/instances/${instance_id}/config`).then((res) => {
      this.setState({
        cartwatchConfig: res.cartwatch_config,
        cartwatchSchema: res.cartwatch_config_schema,
      }, this.unblockRendering);
    }).catch((e) => {
      NotificationManager.error(e.message, "Error loading Cartwatch config!");
    });
  }

  saveData(instance_id, data) {
    if (instance_id === undefined) {
      return;
    }
    Api.put(`/api/instances/${instance_id}/config`, data).then((res) => {
      NotificationManager.success("Cartwatch config saved!");
    }).catch((e) => {
      NotificationManager.error(e.message, "Error saving Cartwatch config!");
    });

  }

  render() {
    if (this.state.block_rendering) {
      return (<div>Loading...</div>);
    }
    else {
      const uiSchema = {
        apps:{
          items:{
            // zones: {
            //   "ui:help": (<a href="#" onClick={()=>{ alert('this will open zones editing dialog'); }}>Edit zones</a>)
            // },
            // alarm_profile: {
            //   "ui:help": (<a href="#" onClick={()=>{ alert('this will open profile editing dialog'); }}>Edit profile</a>)
            // }

          }
        }
      }
      return (<Form schema={this.state.cartwatchSchema} formData={this.state.cartwatchConfig} validator={validator}
                    onSubmit={(e) => this.saveData(this.props.context.instance_id,
                        e.formData)} uiSchema={uiSchema} />)
    }
  }
}

CartwatchConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
        <SessionContext.Consumer>
          {(state) => <Component {...props} context={state} />}
        </SessionContext.Consumer>
    );
  };
}

export default withSessionContext(withI18n(withStyles(dashboardStyle)(CartwatchConfig)));
