import AccountCircle from "@material-ui/icons/AccountCircle";
import Equalizer from "@material-ui/icons/Equalizer";
// import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import PlaylistPlay from "@material-ui/icons/PlaylistPlay";
import Router from "@material-ui/icons/Router";
// import Redirect from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// import NotificationImportant from "@material-ui/icons/NotificationImportant";
import AccountTree from "@material-ui/icons/AccountTree";
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
// import TripOrigin from "@material-ui/icons/TripOrigin"; // old definitions icon
import VideoLabel from "@material-ui/icons/VideoLabel";
import IncidentList from "components/IncidentList.jsx";
import React from "react";
import Config from "views/Config.jsx";
import Definitions from "views/Config/Definitions.jsx";
import CartwatchConfig from "views/Config/CartwatchConfig.jsx";
import CheckoutDashboard from "views/Dashboard/CheckoutDashboard.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import DeviceStatusPage from "views/DeviceStatusPage.jsx";
// import AlarmProfile from "views/AlarmProfile.jsx";
import ControlCenter from "views/ControlCenter.jsx";
import UserSettings from "views/UserSettings.jsx";
// import Zones from "views/Zones.jsx";

let dashboardRoutesPlatform = [
  {
    path: "/user_settings",
    name: "user_settings",
    hidden: true,
    icon: AccountCircle,
    component: (props) => <UserSettings {...props} />,
  },
  {
    path: "/local/control_center",
    name: "local.controlCenter",
    icon: VideoLabel,
    component: (props) => <ControlCenter {...props} />,
  },
  {
    path: "/local/definitions",
    name: "local.definitions",
    icon: AccountTree,
    component: (props) => <Definitions {...props} />,
  },
  {
    path: "/local/config",
    name: "local.config",
    icon: PermDataSettingIcon,
    component: (props) => <CartwatchConfig {...props} />,
  },
  {
    path: "/local/global_config",
    name: "local.globalConfig",
    icon: SettingsIcon,
    component: (props) => <Config {...props} />,
  },
  {
    path: "/local/incidents",
    name: "local.incidents",
    icon: PlaylistPlay,
    component: (props) => <IncidentList {...props} />,
  },
  {
    path: "/local/statistics",
    name: "local.statistics",
    icon: Equalizer,
    component: (props) => <Dashboard {...props} />, // TODO: platform
  },
  // {
  //   path: "/admin",
  //   name: "local.admin",
  //   icon: Equalizer,
  //   component: () => <Redirect to="/admin" replace />,
  // },
  { redirect: true, path: "/", pathTo: "/local/control_center", name: "Control Center" },
];

let dashboardRoutesEntrance = [
  {
    path: "/user_settings",
    name: "sidebar.user_settings",
    hidden: true,
    icon: AccountCircle,
    component: (props) => <UserSettings {...props} />,
  },
  {
    path: "/local/control_center",
    name: "local.controlCenter",
    icon: VideoLabel,
    component: (props) => <ControlCenter {...props} />,
  },
  {
    path: "/local/definitions",
    name: "local.definitions",
    icon: AccountTree,
    component: (props) => <Definitions {...props} />,
  },
  {
    path: "/local/global_config",
    name: "local.globalConfig",
    icon: SettingsIcon,
    component: (props) => <Config {...props} />,
  },
//   {
//     path: "/local/zones",
//     name: "local.zones",
//     icon: PhotoSizeSelectSmallIcon,
//     component:  (props) => <Zones {...props} app_id="entrance" definitions_file_id="apps" instance_id="cartwatch" zones_path="/etc/opt/cartwatch/zones.json" />,
//   },
//   {
//     path: "/local/alarm_profile",
//     name: "local.alarmProfile",
//     icon: NotificationImportant,
//     component: (props) => <AlarmProfile {...props} />,
//   },
  {
    path: "/local/incidents",
    name: "local.incidents",
    icon: PlaylistPlay,
    component: (props) => <IncidentList {...props} />,
  },
  {
    path: "/local/statistics",
    name: "local.statistics",
    icon: Equalizer,
    component: (props) => <Dashboard {...props} />,
  },
  { redirect: true, path: "/", pathTo: "/local/control_center", name: "Control Center" },
];

let dashboardRoutesCheckout = [
  {
    path: "/local/control_center",
    name: "local.controlCenter",
    icon: VideoLabel,
    component: (props) => <ControlCenter {...props} />,
  },
  {
    path: "/local/definitions",
    name: "local.definitions",
    icon: AccountTree,
    component: (props) => <Definitions {...props} />,
  },
  {
    path: "/local/global_config",
    name: "local.globalConfig",
    icon: SettingsIcon,
    component: (props) => <Config {...props} />,
  },
//   {
//     path: "/local/zones",
//     name: "local.zones",
//     icon: PhotoSizeSelectSmallIcon,
//     component: (props) => <Zones {...props} app_id="checkout" definitions_file_id="apps" instance_id="cartwatch" zones_path="/etc/opt/cartwatch/zones.json" />,
//   },
//   {
//     path: "/local/alarm_profile",
//     name: "local.alarmProfile",
//     icon: NotificationImportant,
//     component: (props) => <AlarmProfile {...props} />,
//   },
  {
    path: "/user_settings",
    name: "sidebar.user_settings",
    hidden: true,
    icon: AccountCircle,
    component: (props) => <UserSettings {...props} />,
  },
  {
    path: "/local/statistics",
    name: "local.checkout",
    icon: ShoppingCart,
    component: (props) => <CheckoutDashboard {...props} />,
  },
  { redirect: true, path: "/", pathTo: "/local/control_center", name: "Control Center" }, // todo: translation?
];

let dashboardRoutesCloud = [
  {
    path: "/user_settings",
    name: "sidebar.user_settings",
    hidden: true,
    icon: AccountCircle,
    component: (props) => <UserSettings {...props} />,
  },
  {
    path: "/dashboard",
    name: "cloud.statistics",
    icon: Equalizer,
    component: (props) => <Dashboard {...props} />,
  },
  {
    path: "/checkout",
    name: "cloud.checkout",
    icon: ShoppingCart,
    component: (props) => <CheckoutDashboard {...props} />,
  },
  {
    path: "/incidents",
    name: "cloud.incidents",
    icon: PlaylistPlay,
    component: (props) => <IncidentList {...props} />,
  },
  {
    path: "/devices",
    name: "cloud.devices",
    icon: Router,
    component: (props) => <DeviceStatusPage {...props} />,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/dashboard",
    name: "cloud.statistics",
  },
];

export {
  dashboardRoutesPlatform,
  dashboardRoutesEntrance,
  dashboardRoutesCheckout,
  dashboardRoutesCloud,
};
